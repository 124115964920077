.alert-container {
    position: fixed;
    top: 20%;
    right: 15%;
    background-color: whitesmoke;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 70%;
    display: flex;
    flex-direction: column;
  }
  
  .alert-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .alert-close-button {
    background: #007BFF;
    border: none;
    color: white;
    padding: 10px 20px;
    width: 90%;
    font-weight: bold;
    cursor: pointer;
  }
  
  .alert-progress-bar {
    background-color: #ccc;
    height: 5px;
    border-radius: 3px;
    margin-top: 10px;
  }
  
  .alert-progress {
    background-color: #4caf50;
    height: 100%;
    border-radius: 3px;
  }
  